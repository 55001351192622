<template>
	<v-card flat height="100%" class="wr_grey_1">
		<WizardMap v-if="map" v-model="map" :input="map"></WizardMap>
	</v-card>
</template>

<script>
import WizardMap from "@/components/shared/tools/wizardMindmap/Index.vue";
import { mapGetters } from "vuex";

export default {
	name: "WizardMindmapExplorePage",

	data() {
		return {
			map: null,
		};
	},

	components: {
		WizardMap,
	},

	mounted() {
		this.getMap();
	},

	computed: {
		...mapGetters({
			isOwner: "wizardMindmap/isOwner",
		}),
	},

	methods: {
		async getMap() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("wizardMindmap/get", {
					uuid: this.$route.params.id,
				});

				if (!response || !Object.keys(response).length) {
					throw new Error("app.ntfy.err.board_not_found", {
						cause: "werCustom",
					});
				}

				if (!this.isOwner(response.user) && !response.is_sharing_active) {
					throw new Error("app.ntfy.err.not_activated_for_sharing", {
						cause: "werCustom",
					});
				}

				this.map = response;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style lang="scss">
@import "@/assets/styles/buttonGroup.scss";
</style>
